import React, { FC } from 'react'

import { Text } from 'rebass'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import { compose, border } from 'styled-system'

import Grid from '../../ui/Grid'
import PageHeading from '../PageHeading'
import GridItem from '../../ui/GridItem'
import useIntl from '../../states/useIntl'
import ContainerFixed from '../../ui/ContainerFixed'

import { Navigation, Section } from './components'

import discoverIcon from '../../content/images/icons/service_discover.svg'
import designIcon from '../../content/images/icons/service_design.svg'
import buildIcon from '../../content/images/icons/service_build.svg'
import scaleIcon from '../../content/images/icons/service_scale.svg'

const HEADER_HEIGHT = 90

const BorderedGridItem = styled(GridItem)(compose(border))

const NoWrapPageHeading = styled(PageHeading)`
  white-space: nowrap;
`

export const sections = [
  {
    id: 'discover',
    icon: discoverIcon,
  },
  {
    id: 'design',
    icon: designIcon,
  },
  {
    id: 'build',
    icon: buildIcon,
  },
  {
    id: 'scale',
    icon: scaleIcon,
  },
]

const Services: FC = () => {
  const { t } = useIntl()
  const theme = useTheme()

  return (
    <>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
        `}
      />
      <ContainerFixed>
        <Grid flexDirection={['column', 'initial']}>
          <GridItem flex={['initial', 1, 1]}>
            <NoWrapPageHeading
              pt={[4, 5]}
              pb={[0, 1]}
            >
              {t('meta.title')}
            </NoWrapPageHeading>
            <Navigation
              pt={5}
              items={sections}
              top={[0, HEADER_HEIGHT]}
              position={['initial', 'sticky']}
            />
          </GridItem>
          <BorderedGridItem
            pb={8}
            flex={['initial', 2, 3]}
            borderLeft={['none', `1px solid ${theme.colors.divider}`]}
          >
            <Text
              id="__init"
              color="text"
              px={[0, 4, 6]}
              pt={[4, 5, 5, 6]}
              fontWeight="bold"
              mt={[0, HEADER_HEIGHT]}
              fontSize={[14, 14, 16]}
            >
              {t('meta.description')}
            </Text>
            {
              sections.map(section => (
                <Section
                  id={section.id}
                  key={section.id}
                  pt={HEADER_HEIGHT}
                  title={t(`section.${section.id}.title`)}
                  content={t(`section.${section.id}.content`)}
                />
              ))
            }
          </BorderedGridItem>
        </Grid>
      </ContainerFixed>
    </>
  )
}

export default Services
