import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { GlobalPageContext } from '../types'
import Services from '../components/Services'
import { ServicesQuery } from '../../graphql-types'
import PageContainer from '../components/PageContainer'
import createPageContainerProps from '../createPageContainerProps'

type Props = PageProps<ServicesQuery, GlobalPageContext>

const ServicesPage: FC<Props> = ({ data, pageContext }) => {
  const { seoTranslations, commonTranslations, servicesTranslations } = data
  const t = { ...seoTranslations!.json!, ...commonTranslations!.json!, ...servicesTranslations!.json! }

  return (
    <PageContainer
      translations={t}
      {...createPageContainerProps(data, pageContext)}
    >
      <Services />
    </PageContainer>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query Services($pathRegex: String!, $lang: String!, $pageId: String!) {
    ...PageData

    servicesTranslations: file(
      name: { regex: $pathRegex }
      extension: { eq: "json" }
      absolutePath: { regex: "/translations/" }
    ) {
      json: childTranslationsJson {
        section {
          discover {
            title
            content
          }
          design {
            title
            content
          }
          build {
            title
            content
          }
          scale {
            title
            content
          }
        }
      }
    }
  }
`
