import React, { FC, ComponentProps as CP, useCallback } from 'react'
import Scrollspy from 'react-scrollspy'

import { Image, Text } from 'rebass'
import styled from '@emotion/styled'
import { DefaultTheme } from 'styled-components'
import { compose, position, PositionProps } from 'styled-system'

import Link from '../../../ui/Link'
import List from '../../../ui/List'
import ListItem from '../../../ui/ListItem'
import useIntl from '../../../states/useIntl'

type Props = CP<typeof List> & PositionProps & {
  items: Array<{
    id: string
    icon: string
  }>
}

const AnchorLink = styled(Link)<{}, DefaultTheme>`
  height: 48px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  border-left: 4px solid ${props => props.theme.colors.divider};
  .active & {
    border-left: 4px solid ${props => props.theme.colors.primary};
  };
`

const PositionedList = styled(List)(compose(position))

const Navigation: FC<Props> = ({ items, ...props }) => {
  const { t } = useIntl()

  const ScrollspyContainer = useCallback((props) => (
    <Scrollspy
      items={['__init', ...items.map(item => item.id)]}
      currentClassName="active"
      offset={-150}
      {...props}
    />
  ), [items])

  return (
    <PositionedList
      as={ScrollspyContainer}
      {...props}
    >
      <li />
      {
        items.map(item => (
          <ListItem
            mb={4}
            key={item.id}
          >
            <AnchorLink
              to={`#${item.id}`}
            >
              <Image
                width={48}
                height={48}
                mr={[3, 3, 4]}
                ml={[4, 4, 5]}
                src={item.icon}
              />
              <Text
                color="text"
                fontSize={16}
              >
                {t(`section.${item.id}.title`)}
              </Text>
            </AnchorLink>
          </ListItem>
        ))
      }
    </PositionedList>
  )
}

export default Navigation
