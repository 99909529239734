import React, { FC, ComponentProps as CP } from 'react'

import styled from '@emotion/styled'
import { Box, Flex, Text } from 'rebass'
import { DefaultTheme } from 'styled-components'

type Props = CP<typeof Box> & {
  id: string
  title: string
  content: string
}

const Circle = styled(Box)<{}, DefaultTheme>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.primary};
`

const Section: FC<Props> = ({ id, title, content, ...props }) => {
  return (
    <Box
      id={id}
      as="section"
      {...props as any}
    >
      <Flex>
        <Box
          my={2}
          pt={5}
          mr={3}
          ml={-3}
          width={0}
          display={['none', 'block']}
        >
          <Circle
            mt={1}
            mr="12px"
            ml="-12px"
          />
        </Box>
        <Box
          px={[0, 4, 6]}
        >
          <Text
            py={5}
            as="h2"
            color="text"
            fontSize={32}
          >
            {title}
          </Text>
          <Text
            as="p"
            color="text"
            fontSize={[14, 14, 16]}
          >
            {content}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default Section
